import 'bootstrap/js/dist/util'
import 'bootstrap/js/dist/button'
import 'bootstrap/js/dist/collapse'
import 'select2/dist/js/select2.min'
import './scss/main.scss'

function goToEquipementDetailPage(slug) {
  const location = document.location;
  document.location.href = location.protocol + "//" + location.host + "/" + slug;
}
window.goToEquipementDetailPage = goToEquipementDetailPage;

$(document).ready(function() {
  $('.custom-select').select2();
});
